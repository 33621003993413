<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-if="!loading">
          <crud-list
            v-model="calculos"
            :headers="headers"
            :actions="actions"
            :permission="'list_calculo'"
            :sort-by="['data_referencia']"
            :sort-desc="[false]"
            :slots="[
              'item.status',
              'item.data_referencia',
              'item.controle.data_inicio',
              'item.controle.data_termino',
              'item.controle.status'
            ]"
          >
            <template slot="datas">
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <data-field
                  v-model="data_inicial"
                  :label="'Data Inicial'"
                  :data-max="data_final"
                  :mes-ano="true"
                  @set="handleDataFilter()"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <data-field
                  v-model="data_final"
                  :label="'Data Final'"
                  :data-min="data_inicial"
                  :mes-ano="true"
                  @set="handleDataFilter()"
                />
              </v-col>
            </template>
            <template
              v-if="hasPermission('executar_calculo')"
              v-slot:btnCadastro
            >
              <v-col>
                <v-btn
                  v-if="!calculando"
                  block
                  color="primary"
                  rounded
                  class="text-none text-white"
                  @click="NEW_CALC"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-alarm-panel
                  </v-icon>
                  Calcular Eventos Mensais
                </v-btn>
                <v-btn
                  v-else
                  block
                  rounded
                  class="text-none text-white"
                  color="warning"
                >
                  <v-icon
                    dark
                    left
                    class="mdi-spin"
                  >
                    mdi-clock-time-three
                  </v-icon>
                  Aguarde... Calculando eventos mensais
                </v-btn>
                <calculo-dialog />
              </v-col>
            </template>
            <template
              v-if="hasPermission('fechar_calculo')"
              v-slot:[`item.status`]="{ item }"
            >
              <v-checkbox
                v-model="item.status"
                class="mt-0"
                color="success"
                :loading="false"
                hint="Abrir ou Fechar cáculo!"
                hide-details
                @change="TOGGLE_CALCULO_STATUS(item)"
              />
            </template>
            <template v-slot:[`item.data_referencia`]="{ item }">
              <span>{{ moment( item.data_referencia ).format( "MM/YYYY") }}</span>
            </template>
            <template v-slot:[`item.controle.data_inicio`]="{ item }">
              <span>{{ moment( item.controle.data_inicio ).format( "DD/MM/YYYY hh:mm:ss") }}</span>
            </template>
            <template v-slot:[`item.controle.data_termino`]="{ item }">
              <span>{{ moment( item.controle.data_termino ).format( "DD/MM/YYYY hh:mm:ss") }}</span>
            </template>
            <template v-slot:[`item.controle.status`]="{ item }">
              <span>{{ item.controle.status === 'PR' ? 'Processando' : item.controle.status === 'FS' ? 'Finalizado' : 'Erro' }}</span>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import CalculosStore, { BOOTSTRAP, TOGGLE_CALCULO_STATUS, PERIODO, NEW_CALC, CLOSE, CALCULA } from '@/store/modules/calculos'
  import CalculoDialog from './CalculoDialog.vue'
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import { sortDateTime } from '../../utils/sorts'
  import api from '@/api/api'
  export default {
    components: {
      CalculoDialog,
    },
    data () {
      return {
        data_inicial: 'from',
        data_final: 'to',
        timeChecaCalculo: null,
        actions: [{
          title: 'Editar regional',
          color: 'info darken-3',
          click: item => this.getRegional(item),
          icon: 'mdi-pencil',
          permission: 'editar_regional',
        }],
        headers: [
          { align: 'center', sortable: false, groupable: false, text: 'Fechado', value: 'status', width: '40px', floatingfilter: true },
          { align: 'center', sortable: true, groupable: false, text: 'Referência', value: 'data_referencia', width: '10px', sort: sortDateTime },
          { align: 'center', sortable: true, groupable: false, text: 'Ínicio', value: 'controle.data_inicio', width: '10px', sort: sortDateTime },
          { align: 'center', sortable: true, groupable: false, text: 'Término', value: 'controle.data_termino', width: '10px', sort: sortDateTime },
          { align: 'center', sortable: true, groupable: false, text: 'Status', value: 'controle.status', width: '40px', floatingfilter: true },
          { align: 'left', sortable: true, groupable: false, text: 'Usuário', value: 'controle.user', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: false, text: 'Observação', value: 'controle.observacao', width: 'auto', floatingfilter: true },

        ],
      }
    },
    computed: {
      ...mapState('calculos', ['loading', 'calculos', 'dialog', 'calculando']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.hasModule('calculos')) { this.$store.registerModule('calculos', CalculosStore) }

      let data_i = new Date()

      if (data_i.getMonth() === 0) {
        data_i = new Date(data_i.getFullYear() - 1, 12, 1)
      } else {
        data_i = new Date(data_i.getFullYear(), data_i.getMonth() - 1, 1)
      }

      let data_f = new Date(data_i.getFullYear(), data_i.getMonth() + 2, 0)
      data_i = data_i.toISOString().slice(0, 10)
      data_f = data_f.toISOString().slice(0, 10)

      this.data_inicial = data_i
      this.data_final = data_f

      this.PERIODO({ data_i, data_f })

      this.BOOTSTRAP()
      this.checaCalculo()
      this.timeChecaCalculo = setInterval(() => { this.checaCalculo() }, 30000)
    },
    destroyed () {
      clearInterval(this.timeChecaCalculo)
    },
    methods: {
      ...mapActions('calculos', [BOOTSTRAP, TOGGLE_CALCULO_STATUS, NEW_CALC, CALCULA]),
      ...mapMutations('calculos', [PERIODO, CLOSE]),
      handleDataFilter () {
        const data_i = new Date(this.data_inicial.substring(0, 4) + '/' + this.data_inicial.substring(5, 7) + '/01')
        const data_f = new Date(this.data_final.substring(0, 4) + '/' + this.data_final.substring(5, 7) + '/01')
        data_i.setTime(data_i.getTime() + data_i.getTimezoneOffset() * 60 * 1000)
        data_f.setTime(data_f.getTime() + data_f.getTimezoneOffset() * 60 * 1000)
        this.PERIODO({ data_i, data_f })
        this.BOOTSTRAP()
      },
      async checaCalculo () {
        const status = await api.listEntidade('controlecalculo/checastatus/PR').then(p => p, () => [])
        this.CALCULA(status[0].length > 0)
      },
    },
  }
</script>
