<template>
  <v-dialog
    v-model="dialog"
    max-width="410px"
    width="410px"
    scrollable
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    style="height=662px !important; max-height=662px !important;"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <span
            class="headline"
          >Selecione o período de Cálculo</span>
        </v-col>
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="px-8 pt-3"
      >
        <v-row
          class="pt-2"
        >
          <v-col cols="6">
            <data-field
              v-model="data_inicial"
              :label="'Período Inicial'"
              :data-max="data_final"
              :mes-ano="true"
            />
          </v-col>
          <v-col cols="6">
            <data-field
              v-model="data_final"
              :label="'Período Final'"
              :data-min="data_inicial"
              :mes-ano="true"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="justify-end"
      >
        <v-btn
          color="error"
          rounded
          class="text-none text-white"
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          depressed
          rounded
          class="text-none text-white"
          @click="submit"
        >
          <v-icon
            dark
            left
          >
            mdi-checkbox-marked-circle
          </v-icon>
          Calcular
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import { CLOSE, CALCULA } from '@/store/modules/calculos'
  import moment from 'moment'
  import api from '@/api/api'
  import Swal from 'sweetalert2'

  export default {
    name: 'CalculoDialog',
    data () {
      return {
        data_inicial: 'from',
        data_final: 'to',
      }
    },
    computed: {
      ...mapState('calculos', ['dialog']),
    },
    created () {
      let data_i = new Date()
      data_i.setMonth(data_i.getMonth() - 1)
      data_i = data_i.toISOString().slice(0, 10)
      const data_f = new Date().toISOString().slice(0, 10)
      this.data_inicial = moment(data_i).format('YYYY/MM/01')
      this.data_final = moment(data_f).format('YYYY/MM/01')
    },

    methods: {
      ...mapActions('calculos', [CALCULA]),
      ...mapMutations('calculos', [CLOSE]),

      submit () {
        const arrayData = []
        const date_inicio = new Date(this.data_inicial.substring(0, 4) + '/' + this.data_inicial.substring(5, 7) + '/01')
        const date_fim = new Date(this.data_final.substring(0, 4) + '/' + this.data_final.substring(5, 7) + '/01')

        date_inicio.setTime(date_inicio.getTime() + date_inicio.getTimezoneOffset() * 60 * 1000)
        date_fim.setTime(date_fim.getTime() + date_fim.getTimezoneOffset() * 60 * 1000)

        const diff = moment(date_fim).diff(moment(date_inicio))
        const meses = Math.round(moment.duration(diff).asMonths()) + 1

        let date_execution = date_inicio
        let duration = 1

        arrayData.push(new Date(date_inicio))

        while (duration < meses) {
          arrayData.push(date_execution)
          date_execution = new Date(date_execution.setMonth(date_execution.getMonth() + 1))
          duration += 1
        }

        api.cadastrarEntidade({ data_referencia: arrayData }, 'calculo/calcula').catch(_err => {
          Swal.fire({
            icon: 'error',
            title: 'Atenção',
            text: _err.message,
          })
        },
        )
        this.CALCULA(true)
        this.CLOSE()
        return null
      },
      cancel () {
        this.CLOSE()
      },
    },

  }
</script>
